<!--
*  TTTech nerve-management-system
*  Copyright(c) 2024. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div class="d-flex">
    <span> {{ tableItem.serviceName }}</span>
    <v-tooltip v-if="showWarning" bottom>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-img
            :id="`iiotDockerRemoteConnectionsWarningIcon_${index}`"
            src="/img/warning.svg"
            max-height="17px"
            max-width="17px"
            class="ml-2 mt-1 mr-2"
          />
        </div>
      </template>
      <span>
        {{ $t('remoteConnection.serviceNotInComposeFile') }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    listOfServices: {
      type: Array,
      default: () => [],
    },
    tableItem: {
      type: Object,
      default: () => ({
        workload: {},
      }),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showWarning() {
      const services = [];
      this.listOfServices.forEach((service) => services.push(service.serviceName));
      return !services.includes(this.tableItem.serviceName);
    },
  },
};
</script>
